import React, { useEffect, useState } from 'react';
import { graphql, PageProps } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import styled from 'styled-components';
import { breakpoint } from 'theme';
import { PageContextType } from 'types';

import Layout from 'components/templates/layout';
import KnowHowPost from 'components/organisms/know-how-post';
import KnowHowSlider, { OtherPostsType } from 'components/organisms/know-how-slider';
import PostHeader, { PostHeaderType } from 'components/organisms/post-header';
import { PostTemplateType } from 'components/organisms/post-renderer';
import LetsTalkBanner, { LetsTalkBannerType } from 'components/molecules/lets-talk-banner';
import Breadcrumb from 'components/atoms/breadcrumb';

import Typography from '../components/atoms/typography';
import useCurrentLanguage from '../hooks/use-current-language';
import { JobOffersType } from '../types/home-page';
import { AllJobOfferType } from '../types/job-offers';

type PostDataType = {
  knowHowPostData: {
    postTemplate: PostTemplateType[];
    otherPosts?: OtherPostsType;
    readMinutes?: string;
    socialsShareLabel?: string;
    showBanner?: null | boolean;
    jobOffers: JobOffersType;
  };
};

type DataProps = {
  knowHowPost: PostHeaderType & PostDataType;
  jobOffersByUnit: AllJobOfferType;
  jobOffersByCategory: AllJobOfferType;
  commonComponents: {
    commonComponentsData: {
      successPartBanner: LetsTalkBannerType;
    };
  };
};
type TocItem = {
  id: string;
  text: string;
};

const StyledBreadcrumbWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.grayscaleGray0};
  padding: ${({ theme }) => theme.gap.sm};

  ${breakpoint.md} {
    padding-block: ${({ theme }) => theme.gap.md};
  }

  .breadcrumb {
    max-width: ${({ theme }) => theme.wrapper};
    margin-inline: auto;

    &__list {
      &__item {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;

        a {
          color: ${({ theme }) => theme.colors.gray100};
        }

        a[aria-current='page'] {
          color: ${({ theme }) => theme.colors.gray60};
        }
      }
    }

    &__separator {
      color: ${({ theme }) => theme.colors.gray80};
    }
  }
`;
const TocWrapper = styled.div`
  margin-left: 16px;
  align-self: baseline;

  [data-variant='title3'] {
    margin-bottom: 24px;
  }

  nav {
    padding-inline: ${({ theme }) => theme.gap.sm};
    margin-block: 32px;
    border-left: 4px solid #838791;
  }

  ${breakpoint.lg} {
    nav {
      max-width: 286px;
      margin-left: 16px;
      margin-top: 0;
    }

    [data-variant='title3'] {
      margin-left: 12px;
    }
  }
`;

const WrapperArticleToc = styled.div`
  margin: 0 auto;
  max-width: calc(726px + 32px);

  ${breakpoint.lg} {
    max-width: 1214px;
    margin: auto;
    display: flex;
    flex-direction: row-reverse;
    position: sticky;
    top: 70px;
    height: 0;
  }
`;

const TocLink = styled(AnchorLink)<{ active: boolean }>`
  display: block;
  margin-bottom: 5px;
  color: ${({ theme }) => theme.colors.gray100};
  text-decoration: none;
  padding-left: 20px;
  padding-top: 8px;
  margin-left: -20px;
  font-size: 18px;
  border-left: 4px solid
    ${({ theme, active }) => (active ? theme.colors.primary50 : theme.colors.gray60)};

  &:hover {
    text-decoration: underline;
    border-left: 4px solid ${({ theme }) => theme.colors.primary50};
  }
  scroll-behavior: smooth;
  ${breakpoint.md} {
    margin-block: 0;
  }
`;
export const KnowHowPostPage = ({ pageContext, data }: PageProps<DataProps, PageContextType>) => {
  const {
    knowHowPost: {
      knowHowPostData: {
        postTemplate,
        otherPosts,
        readMinutes,
        jobOffers,
        socialsShareLabel,
        showBanner,
      },

      categories,
      date,
      featuredImage,
      title,
      tags,
    },
    jobOffersByUnit,
    jobOffersByCategory,
    commonComponents: {
      commonComponentsData: { successPartBanner },
    },
  } = data;
  const [toc, setToc] = useState<TocItem[]>([]);
  const [activeId, setActiveId] = useState<string | null>(null);
  const currentLanguage = useCurrentLanguage();
  const inArticleText = currentLanguage === 'PL' ? 'W tym artykule' : 'In this article';
  const createSlug = (text: string): string =>
    text
      .toLowerCase()
      .replace(/[^\w\s]/g, '')
      .replace(/\s+/g, '-');
  const { typeJobOffer } = pageContext;
  const relevantJobOffers = typeJobOffer === 'unit_type' ? jobOffersByUnit : jobOffersByCategory;

  useEffect(() => {
    const generateToc = (htmlContent: string) => {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = htmlContent;
      const headers = tempDiv.querySelectorAll('h2');
      const tocArray: TocItem[] = [];
      headers.forEach((header) => {
        const text = header.textContent || '';
        const id = createSlug(text);

        // Clone the header and set the id
        const newHeader = header.cloneNode(true) as HTMLElement;
        newHeader.id = id;
        header.parentNode?.replaceChild(newHeader, header);

        tocArray.push({
          id,
          text,
        });
      });
      setToc(tocArray);
    };

    // @ts-ignore
    const combinedContent = postTemplate
      .filter(
        (item) =>
          item.fieldGroupName ===
          ('Know_how_Knowhowpostdata_PostTemplate_Text' || 'Press_Presspostdata_PostTemplate_Text')
      )
      // @ts-ignore
      .map((item) => item?.content)
      .join(' ');

    generateToc(combinedContent);
  }, [postTemplate]);

  useEffect(() => {
    const handleIntersect = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveId(entry.target.id);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersect, {
      rootMargin: '0px 0px -80% 0px',
      threshold: 0.1,
    });

    const headers = document.querySelectorAll('h2');
    headers.forEach((header) => {
      observer.observe(header);
    });

    return () => {
      headers.forEach((header) => {
        observer.unobserve(header);
      });
    };
  }, []);

  return (
    <Layout {...{ pageContext }}>
      <StyledBreadcrumbWrapper>
        <Breadcrumb {...{ pageContext }} />
      </StyledBreadcrumbWrapper>
      <article>
        <PostHeader {...{ categories, date, featuredImage, title, tags, readMinutes }} />
        <WrapperArticleToc>
          {toc.length > 0 ? (
            <div>
              <TocWrapper>
                <Typography variant="title3">{inArticleText}</Typography>
                {toc.length > 0 && (
                  <nav>
                    {toc.map((item) =>
                      item.text ? (
                        <TocLink key={item.id} to={`#${item.id}`} active={item.id === activeId}>
                          {item.text}
                        </TocLink>
                      ) : null
                    )}
                  </nav>
                )}
              </TocWrapper>
            </div>
          ) : null}
        </WrapperArticleToc>
        <KnowHowPost
          {...{ postTemplate, socialsShareLabel, allJobOfferType: relevantJobOffers, jobOffers }}
        />
      </article>

      {showBanner ? <LetsTalkBanner {...successPartBanner} /> : null}
      {otherPosts?.postsList ? (
        <KnowHowSlider variant={showBanner ? 'post-with-banner' : 'post'} {...otherPosts} />
      ) : null}
    </Layout>
  );
};

export const query = graphql`
  query KnowHowPost(
    $id: String
    $commonComponentsSlug: String
    $unitType: String!
    $category: String!
  ) {
    knowHowPost: wpKnowHow(id: { eq: $id }) {
      title
      knowHowPostData {
        readMinutes
        socialsShareLabel
        showBanner
        postTemplate {
          ... on WpKnow_how_Knowhowpostdata_PostTemplate_Text {
            content
            fieldGroupName
          }
          ... on WpKnow_how_Knowhowpostdata_PostTemplate_Stats {
            fieldGroupName
            statsList {
              description
              fieldGroupName
              title
            }
          }
          ... on WpKnow_how_Knowhowpostdata_PostTemplate_ReportForm {
            emailPlaceholder
            fieldGroupName
            formConsent
            text
            getresponseList
            heading
            button {
              label
              ariaLabel
              icon {
                name
              }
            }
          }

          ... on WpKnow_how_Knowhowpostdata_PostTemplate_JobOffers {
            fieldGroupName
            unitType
          }
          ... on WpKnow_how_Knowhowpostdata_PostTemplate_Youtube {
            fieldGroupName
            youtubeLink
          }
          ... on WpKnow_how_Knowhowpostdata_PostTemplate_Instagram {
            fieldGroupName
            instagramLink
          }
          ... on WpKnow_how_Knowhowpostdata_PostTemplate_Linkedin {
            fieldGroupName
            linkedinLink
          }
          ... on WpKnow_how_Knowhowpostdata_PostTemplate_Image {
            fieldGroupName
            image {
              ...WpImageFragment
            }
          }
          ... on WpKnow_how_Knowhowpostdata_PostTemplate_Cite {
            cite
            fieldGroupName
          }
          ... on WpKnow_how_Knowhowpostdata_PostTemplate_BannerTextLink {
            fieldGroupName
            heading
            description
            image {
              ...WpImageFragment
            }
            linkButton {
              label
              link {
                ariaLabel
                internal: wpInternal {
                  ... on WpPage {
                    id
                    uri
                  }
                }
                external {
                  target
                  url
                }
              }
            }
          }
          ... on WpKnow_how_Knowhowpostdata_PostTemplate_Author {
            additionalLabel
            fieldGroupName
            firstName
            heading
            lastName
            position
            avatar {
              ...WpImageFragment
            }
          }
        }
        jobOffers: jobOffersSection {
          subHeading: subTitle
          heading
          linkButton {
            label
            link {
              internal: wpInternal {
                ... on WpPage {
                  uri
                }
              }
              ariaLabel
              external {
                target
                url
              }
            }
          }
        }
        otherPosts {
          heading
          subHeading
          linkButton {
            icon {
              name
            }
            label
            link {
              ariaLabel
              internal: wpInternal {
                ... on WpPage {
                  uri
                }
              }
              external {
                target
                url
              }
            }
          }
          postButton {
            label
            ariaLabel
          }
          postsList {
            ... on WpKnow_how {
              id
              featuredImage {
                node {
                  ...WpImageFragment
                }
              }
              date(formatString: "DD.MM.YYYY")
              tags {
                nodes {
                  name
                  uri
                }
              }
              categories {
                nodes {
                  name
                  slug
                }
              }
              title
              uri
              slug
            }
            ... on WpKnow_how {
              id
              featuredImage {
                node {
                  ...WpImageFragment
                }
              }
              date(formatString: "DD.MM.YYYY")
              tags {
                nodes {
                  name
                }
              }
              categories {
                nodes {
                  name
                }
              }
              title
              uri
              slug
            }
            ... on WpPress {
              id
              featuredImage {
                node {
                  ...WpImageFragment
                }
              }
              date(formatString: "DD.MM.YYYY")
              tags {
                nodes {
                  name
                }
              }
              title
              uri
              slug
            }
            ... on WpPress {
              id
              featuredImage {
                node {
                  ...WpImageFragment
                }
              }
              date(formatString: "DD.MM.YYYY")
              tags {
                nodes {
                  name
                }
              }
              title
              uri
            }
          }
        }
      }
      author {
        node {
          lastName
          firstName
          description
          avatar {
            url
          }
        }
      }
      categories {
        nodes {
          name
        }
      }
      date(locale: "PL", formatString: "DD.MM.YYYY")
      featuredImage {
        node {
          ...WpImageFragment
          template {
            templateName
          }
        }
      }
      tags {
        nodes {
          name
        }
      }
    }

    jobOffersByUnit: allWpJobOffer(
      sort: { fields: [date], order: DESC }
      filter: { offersData: { unit: { eq: $unitType } } }
    ) {
      edges {
        node {
          uri
          title
          date
          offersData {
            consultant
            category
            voivodship
            city
            contracttypes
            salaryfrom
            salaryto
            unit
          }
        }
      }
    }

    jobOffersByCategory: allWpJobOffer(
      sort: { fields: [date], order: DESC }
      filter: { offersData: { category: { eq: $category } } }
    ) {
      edges {
        node {
          uri
          title
          date
          offersData {
            consultant
            category
            voivodship
            city
            contracttypes
            salaryfrom
            salaryto
            unit
          }
        }
      }
    }
    commonComponents: wpPage(slug: { eq: $commonComponentsSlug }) {
      commonComponentsData {
        successPartBanner {
          heading
          description
          linkButton {
            label
            icon {
              name
            }
            link {
              ariaLabel
              internal: wpInternal {
                ... on WpPage {
                  uri
                }
              }
              external {
                target
                url
              }
            }
          }
        }
      }
    }
  }
`;

export default KnowHowPostPage;
